.home, .welcome {

  .language {
    margin: 30px 0 40px 0;
  }

  .logos {
    margin-top: 100px;
  }
}
