.slider {
  width: 80% !important;
  margin-top: 30px;

  .slider-handle, .tooltip {
    display: none;
  }
}

.h-slider {
  & + .row {
    font-weight: 100;
    font-size: 1.3em;
    padding-top: 10px;
  }
}

.sliderCaption {
  margin-top: 20px;
  margin-bottom: 30px;
  display: block;
  font-style: italic;
  font-size: 1.6em;
}
