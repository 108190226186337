html {
  height: 100%;
}
body {
  height: 100%;
  font-family: 'Lato', sans-serif;
  color: #FFF;
  margin: 0;
  background-color: #348ac7;
  background: linear-gradient(to bottom, #348ac7 0%, #83b8de 100%) no-repeat fixed;
}
/*
  Typography
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/
h1,
h2,
h3,
p {
  color: #FFF;
  font-weight: 100;
}
h1 {
  font-size: 3.4em;
}
h2 {
  font-size: 2.8em;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-style: italic;
  margin-bottom: 40px;
}
h2.nextButton {
  font-size: 2.3em;
}
h3 {
  font-size: 2.3em;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
}
h3 a {
  font-weight: 300;
}
h2 .btn,
h3 .btn {
  vertical-align: middle;
  margin-top: -0.6em;
}
a {
  color: #006699;
  text-decoration: none !important;
}
p {
  font-size: 1.8em;
  line-height: 1.5em;
  margin-bottom: 30px;
}
.btn-next {
  background: #83b8de;
  color: #FFF;
  border: none;
}
.btn-next:hover {
  color: #348ac7;
}
.btn-lg-blue {
  background: #348ac7;
  color: #FFF;
  border: none;
  padding: 16px 30px;
  font-size: 1.8em;
  min-width: 180px;
  font-weight: 400;
}
.btn-lg-blue:hover {
  background: #FFF;
  color: #348ac7;
}
.btn-shadow {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 7;
  -moz-border-radius: 7;
  border-radius: 7px;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border: solid #ffffff 3px;
  text-decoration: none;
  font-size: 1.8em;
  font-weight: 100;
}
.btn-shadow:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  border: solid #ffffff 3px;
  color: #ffffff;
  text-decoration: none;
}
.navbar-default {
  background: none;
  border: none;
  color: #FFFFFF;
}
.navbar-default p {
  font-size: 14px;
}
.navbar-default .navbar-brand,
.navbar-default .navbar-text {
  color: #FFF;
}
.navbar-default .navbar-brand img {
  width: 70px;
  height: 70px;
}
.navbar-default .navbar-nav li a {
  color: #FFF;
}
.navbar-default .navbar-nav li a:hover {
  color: #FFF;
  text-decoration: underline;
}
.navbar-default .navbar-nav .open {
  background: none;
}
.navbar-default .navbar-nav .open a {
  background: none;
}
.navbar-default .navbar-nav .open a:hover,
.navbar-default .navbar-nav .open a :focus {
  color: #FFF;
  background: none;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  background: none !important;
  color: #FFF !important;
}
.dropdown-menu {
  background: none;
}
.panel,
.panel-default {
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;
}
.panel .panel-heading,
.panel-default .panel-heading {
  color: #FFF;
  background: none;
  border: none;
}
.panel .panel-body h1,
.panel-default .panel-body h1 {
  margin-bottom: 50px;
}
.panel .panel-body ul,
.panel-default .panel-body ul {
  text-align: center;
  zoom: 1;
  *display: inline;
  font-size: 1.9em;
  font-weight: 200;
  list-style: none;
  margin: 40px 0 60px 0;
}
.staffLogin {
  display: none;
}
.has-error .form-control {
  border-color: #FCC87E;
}
span.help-block {
  color: #FCC87E !important;
  font-size: 1.6em;
}
.newCode {
  font-size: 6em;
}
.init_hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}
span.mark {
  color: #348ac7;
  background-color: #FFF;
  box-shadow: 0.4em 0 0 #FFF, -0.2em 0 0 #FFF;
  font-style: italic;
}
.home .language,
.welcome .language {
  margin: 30px 0 40px 0;
}
.home .logos,
.welcome .logos {
  margin-top: 100px;
}
.digest .thumbnail {
  min-height: 520px;
  background: none;
  border: none;
}
.digest .thumbnail .preview {
  padding: 2px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.digest .thumbnail img {
  width: 100%;
}
.digest .thumbnail .caption h3,
.digest .thumbnail .caption p {
  color: #FFF;
}
.digest .thumbnail .caption h3 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-style: italic;
}
.digest .thumbnail .caption p {
  font-size: 1.5em;
  min-height: 120px;
}
.question {
  margin-bottom: 60px;
}
.question ul {
  display: none;
  margin: 10px 0 50px 0 !important;
}
.question ul li {
  font-style: italic;
}
.question::after {
  content: "";
  margin-top: 60px;
  border: 0;
  display: block;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
}
.vjs-progress-control {
  visibility: hidden;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
}
.slide .panel-heading {
  font-size: 1.8em;
  margin-bottom: 20px;
}
.slide h1 + img.portrait {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}
.slide .table-striped {
  font-size: 1.8em;
  font-weight: 100;
}
.slide .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.slide p.init_hidden {
  position: absolute;
}
.slide ul {
  list-style: inherit !important;
  text-align: left !important;
  width: 80%;
  margin: 40px auto 60px auto !important;
}
.slide ul li {
  margin-bottom: 1.2em;
}
.slide ul.list-group {
  width: 100%;
}
.slide ul.list-group li {
  margin-bottom: -1px;
}
.slide ul.popOver li {
  background-color: rgba(255, 255, 255, 0.1);
}
.slide ul.popOver li a {
  display: inline-block;
  padding: 8px 20px;
  width: 100%;
  border-radius: 3px;
  color: #006699;
  font-weight: 300;
}
.slide ul.popOver li a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.18);
}
.slide .well {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.8em;
  font-style: italic;
  font-weight: 400;
}
.slide .well h3 {
  font-size: 1.3em;
  margin-top: 0;
}
.circleDiagram {
  padding-top: 20px;
  padding-bottom: 260px;
}
.circleDiagram p.info {
  width: 95%;
  z-index: 1;
}
.circleDiagram p.info.animated {
  z-index: 2;
}
.circleDiagram span.audio {
  display: block;
  font-style: italic;
  margin-top: 20px;
}
.diagram {
  margin: 40px auto;
  padding-top: 40px;
  position: relative;
  height: 510px;
}
.circle {
  position: absolute;
  margin: 0 auto;
}
.sc_object {
  color: #FFF;
  font-weight: 100;
  cursor: pointer;
  cursor: hand;
}
.sc_object.active {
  background: rgba(255, 255, 255, 0.8) !important;
}
.sc_object span {
  font-size: .9em;
  line-height: 1.2em;
  color: #006699;
  font-weight: 400;
  vertical-align: middle;
  display: inline-block;
  padding: 0 14px;
}
.list-group .list-group-item {
  background-color: transparent;
  text-align: left;
}
.list-group .list-group-item span {
  font-size: .8em;
}
.list-group .list-group-item .col-md-6 {
  display: inline-block;
}
.list-group .list-group-item .col-md-8 .btn-group .btn {
  font-size: 14px;
}
.list-group .list-group-item .btn-group {
  vertical-align: middle;
}
.list-group .list-group-item .btn-group .btn {
  background: #348ac7;
  color: #FFF;
  border: #83b8de 1px solid;
  font-weight: 300;
  font-size: 17px;
}
.list-group .list-group-item .btn-group .btn.active {
  background: #83b8de;
}
.popover {
  max-width: inherit;
}
.popover .popover-content {
  color: #348ac7;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 1.6em;
}
.vcenter {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: -4px;
}
.controls {
  position: absolute;
  right: 10px;
  bottom: -100px;
  width: 120px;
  margin-bottom: 100px;
}
.controls .previous {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 34.6px 20px 0;
  border-color: transparent #348ac7 transparent transparent;
}
.controls .home {
  position: absolute;
  left: 40px;
  width: 40px;
  height: 41px;
  color: #FFF;
  font-size: 1.9em;
  background: #83b8de;
}
.controls .home span.glyphicon {
  line-height: 1.5em;
}
.controls .next {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 34.6px;
  border-color: transparent transparent transparent #348ac7;
}
/**
 * Survey design
 */
.survey {
  margin: 80px 0;
  padding: 0 40px;
  text-align: left;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 1.4em;
  font-weight: 100;
}
.survey .row {
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.survey .row:last-of-type {
  border-bottom: none;
}
.survey .row label {
  font-weight: 100;
  line-height: 1.1em;
}
.survey .row .checkbox {
  margin: 0 0 10px 0;
}
.survey .row .form-control {
  margin-left: 15px;
}
.survey .row .slider {
  width: 100% !important;
}
.survey .row .h-slider + .row {
  font-size: .9em;
  border: none;
}
.survey .row .h-slider + .row .pull-right {
  text-align: right;
}
.toggleList li {
  text-align: center !important;
  color: #006699;
  font-weight: 400;
  cursor: pointer;
  cursor: hand;
  list-style: none;
}
.toggleList li.answer {
  color: #fff;
  font-weight: 100;
  cursor: none;
  display: none;
}
.pyramid {
  margin-bottom: 30px;
}
.pyramid .segment1,
.pyramid .segment2,
.pyramid .segment3 {
  height: 180px;
  margin: 0 auto;
  cursor: pointer;
  cursor: hand;
  display: table;
  padding: 10px 40px;
}
.pyramid .segment1 div.content,
.pyramid .segment2 div.content,
.pyramid .segment3 div.content {
  display: table-cell;
  vertical-align: middle;
}
.pyramid .segment1 div.content h3,
.pyramid .segment2 div.content h3,
.pyramid .segment3 div.content h3 {
  font-size: 1.9em;
}
.pyramid .segment1 div.content p,
.pyramid .segment2 div.content p,
.pyramid .segment3 div.content p {
  font-size: 1.5em;
}
.pyramid .segment1:hover,
.pyramid .segment2:hover,
.pyramid .segment3:hover {
  border: 3px solid rgba(255, 255, 255, 0.2);
}
.pyramid .segment1 {
  border: 3px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.1);
  width: 40%;
}
.pyramid .segment2 {
  border: 3px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.2);
  width: 66%;
}
.pyramid .segment3 {
  border: 3px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
}
.pyramid + .bg-info {
  background-color: transparent;
  font-style: italic;
}
.pyramid + .bg-info:before {
  font-family: FontAwesome;
  content: "\f129";
  display: inline-block;
  padding-right: 8px;
  vertical-align: inherit;
}
.popover {
  max-width: 80%;
}
.makePlan .col-md-6:nth-of-type(1) {
  text-align: left;
}
.makePlan .col-md-6:nth-of-type(1) h3:nth-of-type(1) {
  margin-top: 5px;
}
.makePlan .steps {
  position: relative;
  display: block;
  min-height: 400px;
  border-bottom: 1px solid #FFF;
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
}
.makePlan .steps.step01 {
  background-color: rgba(255, 255, 255, 0.1);
}
.makePlan .steps.step02 {
  background-color: rgba(255, 255, 255, 0.2);
}
.makePlan .steps.step03 {
  background-color: rgba(255, 255, 255, 0.3);
}
.makePlan .steps:last-of-type {
  border-bottom: none;
}
.makePlan .steps h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.9em;
  width: 90%;
}
.makePlan .steps hr {
  border-top: dotted 2px;
  width: 80%;
  float: left;
  margin-top: 30px;
}
.makePlan .steps .pull-left {
  line-height: 40px;
  font-size: 1.7em;
  font-weight: 100;
  margin-right: 6px;
}
.makePlan .steps .pull-left + hr {
  width: 74%;
}
.makePlan .steps .annotation {
  position: absolute;
  top: 400px;
  right: 0;
  width: 400px;
  height: 40px;
  text-align: left;
  padding-left: 10px;
  font-size: 2em;
  transform: rotate(90deg);
  transform-origin: top right ;
  background-color: rgba(255, 255, 255, 0.3);
}
ul.linkedTable {
  font-size: 1em !important;
}
ul.linkedTable .list-group-item:nth-of-type(1) {
  background: rgba(255, 255, 255, 0.1);
}
ul.linkedTable .list-group-item:nth-of-type(2) {
  background: rgba(255, 255, 255, 0.2);
}
ul.linkedTable .list-group-item:nth-of-type(3) {
  background: rgba(255, 255, 255, 0.3);
}
ul.linkedTable .list-group-item:nth-of-type(4) {
  background: rgba(255, 255, 255, 0.4);
}
ul.linkedTable ul {
  margin-top: 0 !important;
}
ul.linkedTable a {
  font-weight: 400;
}
table.hiddenInfos tr {
  text-align: left;
}
table.hiddenInfos tr td:nth-of-type(1) {
  width: 20%;
}
table.hiddenInfos tr td:nth-of-type(2) {
  width: 20%;
  font-style: italic;
  font-weight: 400;
}
table.hiddenInfos tr td:nth-of-type(3) {
  width: 60%;
}
table.hiddenInfos tr td:nth-of-type(3) ul {
  margin: 10px 0 0 0 !important;
  font-size: 1em;
}
audio {
  vertical-align: sub;
}
p.reference {
  font-style: italic;
  font-size: 1.2em;
}
p.reference a {
  font-size: 1.4em;
}
.slider {
  width: 80% !important;
  margin-top: 30px;
}
.slider .slider-handle,
.slider .tooltip {
  display: none;
}
.h-slider + .row {
  font-weight: 100;
  font-size: 1.3em;
  padding-top: 10px;
}
.sliderCaption {
  margin-top: 20px;
  margin-bottom: 30px;
  display: block;
  font-style: italic;
  font-size: 1.6em;
}
