.slide {

  .panel-heading {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  h1 + img.portrait {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 3px;
    margin-bottom: 30px;
    background-color: #fff;
  }

  .table-striped {
    font-size: 1.8em;
    font-weight: 100;

    & > tbody > tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, .1);
    }
  }

  p.init_hidden {
    position: absolute;
  }

  ul {
    list-style: inherit !important;
    text-align: left !important;
    width: 80%;
    margin: 40px auto 60px auto !important;

    li {
      margin-bottom: 1.2em;
    }
  }

  ul.list-group {
    width: 100%;

    li {
      margin-bottom: -1px;
    }
  }

  ul.popOver {

    li {
      background-color: rgba(255, 255, 255, .1);

      a {
        display: inline-block;
        padding: 8px 20px;
        width: 100%;
        border-radius: 3px;
        color: #006699;
        font-weight: 300;

        &:hover {
          text-decoration: none;
          background-color: rgba(255, 255, 255, .18);
        }
      }
    }
  }

  .well {
    background-color: rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .9);
    font-size: 1.8em;
    font-style: italic;
    font-weight: 400;

    h3 {
      font-size: 1.3em;
      margin-top: 0;
    }
  }
}

.circleDiagram {
  padding-top: 20px;
  padding-bottom: 260px;

  p.info {
    width: 95%;
    z-index: 1;

    &.animated {
      z-index: 2;
    }
  }

  span.audio {
    display: block;
    font-style: italic;
    margin-top: 20px;
  }
}

.diagram {
  margin: 40px auto;
  padding-top: 40px;
  position: relative;
  height: 510px;
}
.circle {
  position: absolute;
  margin: 0 auto;
}

.sc_object {
  color: #FFF;
  font-weight: 100;
  cursor: pointer;
  cursor: hand;

  &.active {
    background: rgba(255, 255, 255, .8) !important;
  }

  span {
    font-size: .9em;
    line-height: 1.2em;
    color: #006699;
    font-weight: 400;
    vertical-align: middle;
    display: inline-block;
    padding: 0 14px;
  }
}

.list-group {

  .list-group-item {
    background-color: transparent;
    text-align: left;

    span {
      font-size: .8em;
      //width: 50%;
      //display: inline-block;
      //vertical-align: middle;
    }

    .col-md-6 {
      display: inline-block;
    }

    .col-md-8 {
      .btn-group {
        .btn {
          font-size: 14px;
        }
      }
    }

    .btn-group {
      vertical-align: middle;

      .btn {
        background: #348ac7;
        color: #FFF;
        border: #83b8de 1px solid;
        font-weight: 300;
        font-size: 17px;

        &.active {
          background: #83b8de;
        }
      }
    }
  }
}

.popover {
  max-width: inherit;

  .popover-content {
    color: #348ac7;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 1.6em;
  }
}

.vcenter {
  float:none;
  display:inline-block;
  vertical-align:middle;
  margin-right:-4px;
}

.controls {
  position: absolute;
  right: 10px;
  bottom: -100px;
  width: 120px;
  margin-bottom: 100px;

  .previous {
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 34.6px 20px 0;
    border-color: transparent #348ac7 transparent transparent;
  }

  .home {
    position: absolute;
    left: 40px;
    width: 40px;
    height: 41px;
    color: #FFF;
    font-size: 1.9em;
    background: #83b8de;

    span.glyphicon {
      line-height: 1.5em;
    }
  }

  .next {
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 34.6px;
    border-color: transparent transparent transparent #348ac7;
  }
}

/**
 * Survey design
 */

.survey {
  margin: 80px 0;
  padding: 0 40px;
  text-align: left;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, .1);
  font-size: 1.4em;
  font-weight: 100;

  .row {
    padding: 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .4);

    &:last-of-type {
      border-bottom: none;
    }

    label {
      font-weight: 100;
      line-height: 1.1em;
    }

    .checkbox {
      margin: 0 0 10px 0;
    }

    .form-inline {

      .form-group {

      }
    }

    .form-control {
      margin-left: 15px;
    }

    .slider {
      width: 100% !important;
    }

    .h-slider {

      & + .row {
        font-size: .9em;
        border: none;

        .pull-right {
          text-align: right;
        }
      }
    }
  }
}

.toggleList {

  li {
    text-align: center !important;
    color: #006699;
    font-weight: 400;
    cursor: pointer;
    cursor: hand;
    list-style: none;

    &.answer {
      color: #fff;
      font-weight: 100;
      cursor: none;
      display: none;
    }
  }
}

.pyramid {
  margin-bottom: 30px;

  .segment1, .segment2, .segment3 {
    height: 180px;
    margin: 0 auto;
    cursor: pointer;
    cursor: hand;
    display: table;
    padding: 10px 40px;

    div.content {
      display: table-cell;
      vertical-align: middle;

      h3 {
        font-size: 1.9em;
      }

      p {
        font-size: 1.5em;
      }
    }

    &:hover {
      border: 3px solid rgba(255, 255, 255, .2);
    }
  }

  .segment1 {
    border: 3px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, .1);
    width: 40%;
  }

  .segment2 {
    border: 3px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, .2);
    width: 66%;
  }

  .segment3 {
    border: 3px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, .3);
    width: 100%;
  }

  & + .bg-info {
   background-color: transparent;
   font-style: italic;

   &:before {
     font-family: FontAwesome;
     content: "\f129";
     display: inline-block;
     padding-right: 8px;
     vertical-align: inherit;
   }
  }
}

.popover {
  max-width: 80%;
}

.makePlan {

  .col-md-6:nth-of-type(1) {
    text-align: left;

    h3:nth-of-type(1) {
      margin-top: 5px;
    }
  }

  .steps {
    position: relative;
    display: block;
    min-height: 400px;
    border-bottom: 1px solid #FFF;
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;

    &.step01 {
      background-color: rgba(255, 255, 255, .1);
    }

    &.step02 {
      background-color: rgba(255, 255, 255, .2);
    }

    &.step03 {
      background-color: rgba(255, 255, 255, .3);
    }

    &:last-of-type {
      border-bottom: none;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 1.9em;
      width: 90%;
    }

    hr {
      border-top: dotted 2px;
      width: 80%;
      float: left;
      margin-top: 30px;
    }

    .pull-left {
      line-height: 40px;
      font-size: 1.7em;
      font-weight: 100;
      margin-right: 6px;

     + hr {
        width: 74%;
      }
    }

    .annotation {
      position: absolute;
      top: 400px;
      right: 0;
      width: 400px;
      height: 40px;
      text-align: left;
      padding-left: 10px;
      font-size: 2em;
      //top: 0;
      //right: 0;
      transform: rotate(90deg);
      transform-origin: top right ;
      background-color: rgba(255, 255, 255, .3);
    }
  }
}

ul.linkedTable {
  font-size: 1em !important;

  .list-group-item:nth-of-type(1) {
    background: rgba(255, 255, 255, .1);
  }

  .list-group-item:nth-of-type(2) {
    background: rgba(255, 255, 255, .2);
  }

  .list-group-item:nth-of-type(3) {
    background: rgba(255, 255, 255, .3);
  }

  .list-group-item:nth-of-type(4) {
    background: rgba(255, 255, 255, .4);
  }

  ul {
    margin-top: 0 !important;
  }

  a {
    font-weight: 400;
  }
}

table.hiddenInfos {

  tr {
    text-align: left;

    td:nth-of-type(1) {
      width: 20%;
    }

    td:nth-of-type(2) {
      width: 20%;
      font-style: italic;
      font-weight: 400;
    }

    td:nth-of-type(3) {
      width: 60%;

      ul {
        margin: 10px 0 0 0 !important;
        font-size: 1em;
      }
    }
  }
}

audio {
  vertical-align: sub;
}

p.reference {
  font-style: italic;
  font-size: 1.2em;

  a {
    font-size: 1.4em;
  }
}
