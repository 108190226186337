.digest {

  .thumbnail {

    min-height: 520px;
    background: none;
    border: none;

    .preview {
      padding: 2px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      -webkit-transition: border .2s ease-in-out;
      -o-transition: border .2s ease-in-out;
      transition: border .2s ease-in-out;
    }

    img {
      width: 100%;
    }

    .caption {

      h3, p {
        color: #FFF;
      }

      h3 {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 700;
        font-style: italic;
      }

      p {
        font-size: 1.5em;
        min-height: 120px;
      }
    }
  }
}
