.question {
  margin-bottom: 60px;

  ul {
    display: none;
    margin: 10px 0 50px 0 !important;

    li {
      font-style: italic;
    }
  }

  &::after {
    content: "";
    margin-top: 60px;
    border: 0;
    display: block;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
  }
}
