html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Lato', sans-serif;
  color: #FFF;
  margin: 0;
  background-color: #348ac7;
  background: linear-gradient(to bottom, #348ac7 0%, #83b8de 100%) no-repeat fixed;
  //-webkit-font-smoothing: antialiased;
}

/*
  Typography
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

h1, h2, h3, p {
  color: #FFF;
  font-weight: 100;
  //margin: 0;
}

h1 {
 font-size: 3.4em;
}

h2 {
  font-size: 2.8em;
  //color: #006699;
  //color: #FFF;
  //color: #80B6DD;
  //color: #FCC87E;
  color: rgba(255,255,255, 0.7);
  font-weight: 700;
  font-style: italic;
  margin-bottom: 40px;

  &.nextButton {
    font-size: 2.3em;
  }
}

h3 {
  font-size: 2.3em;
  font-weight: 400;
  //color: #006699;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;

  a {
    font-weight: 300;
  }
}

h2, h3 {

  .btn {
    vertical-align: middle;
    margin-top: -0.6em;
  }
}

a {
  //text-shadow: 1px 1px 1px rgba(255, 255, 255, .4);
  color: #006699;
  text-decoration: none !important;

  &:link, :visited, :hover {
    //text-decoration: none;
  }
}

p {
  font-size: 1.8em;
  line-height: 1.5em;
  margin-bottom: 30px;
}

.btn-next {
  background: #83b8de;
  color: #FFF;
  border: none;

  &:hover {
    color: #348ac7;
  }
}

.btn-lg-blue {
  background: #348ac7;
  color: #FFF;
  border: none;
  padding: 16px 30px;
  font-size: 1.8em;
  min-width: 180px;
  font-weight: 400;

  &:hover {
    background: #FFF;
    color: #348ac7;
  }
}

.btn-shadow {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 7;
  -moz-border-radius: 7;
  border-radius: 7px;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  border: solid #ffffff 3px;
  text-decoration: none;
  font-size: 1.8em;
  font-weight: 100;

  &:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    border: solid #ffffff 3px;
    color: #ffffff;
    text-decoration: none;
  }
}

.navbar-default {
  background: none;
  border: none;
  color: #FFFFFF;

  p {
    font-size: 14px;
  }

  .navbar-brand, .navbar-text {
    color: #FFF;
  }

  .navbar-brand {

    img {
      width: 70px;
      height: 70px;
    }
  }

  .navbar-nav {

    li {

      a {
        color: #FFF;

        &:hover {
          color: #FFF;
          text-decoration: underline;
        }
      }
    }

    .open {

      background: none;

      a {
        background: none;

        &:hover, :focus {
          color: #FFF;
          background: none;
        }
      }
    }
  }
}

.dropdown-toggle:active, .open .dropdown-toggle {
  background: none !important;
  color:#FFF !important;
}

.dropdown-menu {
  background: none;
}

.panel, .panel-default {
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;

  .panel-heading {
    color: #FFF;
    background: none;
    border: none;
  }

  .panel-body {

    h1 {
      margin-bottom: 50px;
    }

    ul {
      text-align: center;
      //display:-moz-inline-stack;
      zoom:1;
      *display:inline;
      font-size: 1.9em;
      font-weight: 200;
      list-style: none;
      margin: 40px 0 60px 0;
    }
  }
}

.staffLogin {
  display: none;
}

.has-error .form-control {
  //color: #80B6DD;
  //color: #FCC87E;
  border-color: #FCC87E;
}

span.help-block {
  color: #FCC87E !important;
  font-size: 1.6em;
}

.newCode {
  font-size: 6em;
}

.init_hidden {
  opacity:0;
}
.visible {
  opacity:1;
}

span.mark {
  //padding: .2em 10px;
  color: #348ac7;
  background-color: #FFF;
  box-shadow: 0.4em 0 0 #FFF,-0.2em 0 0 #FFF;
  font-style: italic;
}

@import "home";
@import "digest";
@import "poll";
@import "video";
@import "slides";
@import "slider";
